import Vue from 'vue'
import App from './App.vue'
import i18n from '@/locale'
import router from './router'
import ElementUi from 'element-ui'
import './assets/scss/element-variables.scss'
import './utils'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import '@fortawesome/fontawesome-free/css/all.min.css'


Vue.config.productionTip = false

Vue.use(ElementUi);
Vue.use(VueClipboard);

// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	// document.title = `${to.meta.title}`;

	var bLogined = false;

	if (null != store.state.token && store.state.expireTime > ((new Date().getTime()))) {
		bLogined = true;
	}

	if (false == bLogined && to.path !== '/Index' && to.path !== '/ForgotUsername' &&
		to.path !== '/ForgotPassword' && to.path !== '/ResetPassword' && to.path !== '/ConfirmResetEmail' && to
		.name !== 'MemberDonate') {
		console.log("clear")

		console.log(to)

		store.commit("clear");

		next('/Index');
	} else {
		// 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
		if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
			Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
				confirmButtonText: '确定'
			});
		} else {
			console.log("next")

			next();
		}
	}
});


new Vue({
	i18n,
	render: h => h(App),
	router,
	store
}).$mount('#app')