const ko = {
	// Register
	1: '계정 생성',
	2: '로그인 아이디(영문+숫자만 5글자이상~12이하)',
	3: '로그인 아이디는 무조건 알파벳으로 시작 해야 합니다.영문+숫자로만 조합, 5-10 자리로 만들어야 됩니다.(~!@#$% 기호 금지)',
	4: '비밀번호를 입력 (영문+숫자만 10글자이하)',
	5: '비밀번호는 영문+숫자로만 조합. 6-10 자리로 만들어야 됩니다.(~!@#$% 기호 금지)',
	6: '비밀번호 확인',
	7: '이메일 입력(비밀번호 분실시 필요)',
	8: '알파벳,언더라인,숫자, 콤마(.), 작대기(-)로 입력하세요.',
	9: '메일@의 오른쪽에 무조건 콤마(.)가 있어야 됩니다.',
	10: '비밀번호 분실시 본인 인증을 위해 꼭 필요하니.',
	11: '실제 사용중인 이메일 주소를 기입해주세요.',
	12: '남(파테) ',
	13: '여(빨테)',
	14: '인증번호를 입력하세요',
	15: '여기를 클릭하여 인증번호를 넣어주세요.',
	16: '아이디 생성 완료',
	17: '돌아가기',
	18: '여기를 클릭하고 메세지를 보내주세요',
	// Index
	19: '슈퍼탱크 로그인',
	20: '로그인하면 개인 정보 확인 가능합니다~(실적,길드 및 재산 포함）',
	21: '공식 사이트에 개인정보는 ‘업데이트 미완료’로 나타나 있으면 게임 데이터를 기준으로 확인하시면 됩니다.',
	22: '회원 가입',
	23: '게임 다운로드',
	24: '캐쉬충전',
	25: '게임방법안내',
	26: '고급 승급',
	27: '커뮤니티',
	28: '티어 등급',
	29: '전투 방법',
	30: '기대해보세요',
	31: '도구 소개',
	32: '지도 소개',
	33: '탱크 소개',
	34: '신문',
	35: '공지사항',
	36: '미디어 평가',
	37: '플레이어 공략',
	38: '인터넷 블로그 이슈 댓글',
	39: '추가적인 미디어 평가',
	40: '다른 것',
	// Apply
	41: '선택',
	42: '취소',
	43: '통과',
	44: '길드이름',
	45: '길드마스터',
	46: '신청자',
	47: '등급',
	48: '길드 배치시간',
	49: '초대가 승인되었습니다.',
	50: '초대가 거부되었습니다.',
	51: '가입을 취소했습니다.',
	// Create
	52: '길드를 만드세요( ',
	53: ' 한화충전',
	54: '캐쉬 충전',
	55: '로그아웃',
	56: '길드이름은 3-12개 무자부호로 구성해야 됩니다',
	57: '컬러',
	58: '미리보기',
	59: '개설',
	60: '길드 개설 시 기존 길드 가입이 되어 있으면 탈퇴후 길드개설이 가능합니다',
	61: '길드를 성공적으로 개설했습니다',
	// Detail
	62: '길드를 해체합니다',
	63: '길드탈퇴했습니다.',
	64: '조작',
	65: '삭제',
	66: '명칭',
	67: '등급',
	68: '순위',
	69: '점수적립',
	70: '승률',
	71: '마지막 게임',
	72: '팀원을 삭제했습니다',
	73: '길드해체 동의합니까?',
	74: '팁',
	75: '확인',
	76: '취소',
	77: '길드를 성공적으로 해체했습니다',
	78: '취소했습니다',
	79: '길드탈퇴 동의합니까?',
	80: '길드탈퇴 성공했습니다',
	// Guild Index
	81: '선택',
	82: '가입',
	83: '명칭',
	84: '팀장',
	85: '팀원인수',
	86: '개설시간',
	87: '길드 포인트 적립',
	88: '신청서가 제출됐으니 길드마스터의 심의를 기다리세요!',
	// Donate
	89: '충전',
	90: '충전방식',
	91: '충전금액',
	92: 'CASH(한화) ',
	93: '게임캐쉬',
	94: '포인트 적립 기부 연락처',
	95: '충전 지지 성명',
	96: '존경그러운 여려분：',
	97: 'FortressTank는 FortressRed2에 마지막 성역으로 여러분이 같이 지지하고 지켜야합니다.저희는 게임중에 각종기능들 및 리페어후 잔여된 문제들을 업그레이드할 것입니다.',
	98: 'FortressTank를 운행할수 있도록 유지하기 위하여 아래 비용은 전수 무상으로 드릴거니참고하세요！',
	99: 'FortressTank를 사랑해주셔서 아주 감사합니다!',
	100: 'FortressTank 단체 2021.1.1',
	101: '충전중이니 조금만 기다려주세요~...',
	102: '닫기',
	103: 'Paypal충전',
	104: '알리페이 스캔으로 충전',
	105: 'QR코드로 충전',
	106: '충전 완료했습니다',
	// MotifyPassword
	107: '기존 비밀번호를 입력하세요',
	108: '새로운 비밀번호를 입력하세요',
	109: '비밀번호는 알파벳,숫자로만 6-15자리로 입력 가능합니다.',
	110: '비밀번호 다시 입력하세요',
	111: '변경',
	112: '메일을 입력하세요',
	113: '비밀번호 변경 완료됐습니다',
	// Login
	114: '사용자 등록',
	115: '등록',
	// SideBar
	116: '메인 화면',
	117: '길드',
	118: '길드 리스트',
	119: '길드 상세정보',
	120: '길드 개설',
	121: '신청 가입',
	122: '계정',
	123: '비밀번호 변경',
	124: '금액',
	125: '승리횟수',
	126: '패배횟수',
	127: '계정상태',
	// 
	128: '클릭 하여 다운로드',
	129: '주의 하다.',
	130: '반드시 설치 해 야 한다 C++ Runtime',
	131: '상단의 버튼을 클릭해주세요.',
	132: '충전',
	133: '게임점수 적립',
	134: '친구에게 CASH선물.',
	135: '친구의 닉네임',
	136: '게임 닉네임(특수문자 가능 6글자이하)',
	137: '닉네임을 1-6자리로 만들어야 됩니다',
	138: '닉네임을',
	139: '닉네임 변경',
	140: '닉네임 유형',
	141: '일반 아이디(300 한화충전)',
	142: '특문아이디 (1500 한화충전)',
	143: '닉네임 수정 완료.',
	144: '검사=',
	145: '등록되었습니다',
	146: '제목',
	147: '글쓴이',
	148: '비밀번호 찾기',
	149: '다음',
	150: '메시지 발송됨',
	151: '재설정할 비밀번호 입력',
	152: '검색',
	153: '아이디 찾기',
	154: '변경할 이메일 주소를 입력하세요',
	155: '변경할 이메일주소 한번 더 확인',
	156: '이메일 주소 두번 불일치',
	157: '이메일 주소 변경',
	158: '이메일 변경 완료됐습니다',
	159: '새 이메일 주소',
	160: '이메일',
	161: '길드 이름 변경',
	162: '길드 색상 변경',
	163: '길드 이름 변경 동의합니까',
	164: '길드 색상 변경 동의합니까',
	165: '길드 이름 변경  완료했습니다',
	166: '길드 색상 변경 완료했습니다',
	167: '양도',
	168: '길드마스터를 양도 하시겠습니까?',
	169: '길드마스터가 변경되었습니다.',
	170: '주문금액',
	171: '최소 충전금액 1,500 Cash 1,500 Cash 의 배수로만 충전 가능.ex) 1,500, 3,000, 4,500',
	172: '최소 충전금액 1,000 Score 1,000 Score 의 배수로만 충전 가능.ex) 100, 200, 300',
	173: '계정 성별 변경',
	174: '성별 변경이 완료되었습니다.',
	175: '로그인 아이디',
	176: '기존버젼',
	177: 'HD',
	// ErrorCode
	1000: '성공',
	1001: '실패',
	1002: '사용자 이름이 규칙에 맞지 않습니다',
	1003: '비밀번호가 규칙에 맞지 않습니다',
	1004: '이메일이 규칙에 맞지 않습니다',
	1005: '길드 미가입',
	1006: '신분증 규칙에 맞지 않습니다',
	1007: '아이디가 이미 존재합니다',
	1008: '아이디가 올바르지 않습니다',
	1009: '비밀번호가 올바르지 않습니다',
	1010: '계정 잠김',
	1011: '성별이 규칙에 맞지 않습니다',
	1012: '이미 가입한 길드가 있습니다',
	1013: '이미 신청한 길드가 있습니다',
	1014: '동훈장 이상이어야 길드를 개설할 수 있습니다',
	1015: '길드명이 이미 존재합니다',
	1016: '길드가 존재하지 않습니다',
	1017: '길드 미가입',
	1018: '리더만이 심사할 수 있습니다',
	1019: '이 길드의 구성원이 아니므로 목록을 볼 수 없습니다',
	1020: '길드 이름이 올바르지 않습니다',
	1021: '길드 가입신청서 없음',
	1022: '충전과정이 올바르지 않습니다.',
	1023: '금액이 정확하지 않습니다. 길드생성시 3000원이 필요합니다',
	1024: '초대코드가 잘못되었습니다',
	1025: '초대코드가 이미 사용되었습니다',
	1026: '길드 마스터는 삭제할 수 없습니다',
	1057: '알수없는오류',
	1058: '접근권한없음',
	1059: '닉네임이 올바르지 않습니다"',
	1060: '닉네임은 이미 존재합니다',
	1061: '닉네임이 규칙에 맞지 않습니다',
	1062: '금액이 맞지 않습니다',
	1063: '시작시간이 올바르지 않습니다',
	1064: '종료 시간이 잘못되었습니다',
	1065: '금액이 맞지 않습니다',
	1066: 'QR코드 형식이 잘못되었습니다',
	1067: 'QR코드가 올바르지 않습니다',
	1068: '인증번호가 잘못되었습니다.',
	1069: '이메일이 올바르지 않습니다.',
	1070: '암호 재설정 횟수를 초과했습니다.(1일1회)',
}

export default {
	...ko,
}